<template>
    <div>
      <page-title :heading=heading :subheading=subheading></page-title>
      
      <!-- Searchable Datatable card -->
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			<a-row class="pt-4">
				<a-col :span="12">
					<h5 class="font-semibold">Add New Sale</h5>
				</a-col>
				<a-col :span="12" class="text-right mb-2">
					<a-button type="primary" :href="`/shop-management/sales`" class="">
						<a-icon type="arrow-left" theme="outlined" class="text-white mr-0" />Back
					</a-button>
				</a-col>

				<a-col :span="24">
					<a-divider class="mt-1 pt-0"/>
				</a-col>
			</a-row>

			
			<div class="">
			
				<a-form-model
					:model="newSale"
					:form="form"
					class="mb-0 pb-0"
					layout="vertical"
					@submit.prevent="handleSubmit">

					<a-row :gutter="24" class="pb-4">
						<a-col :span="24" :md="12">
							<a-form-item 
								class="my-0 mt-4 pb-0" 
								label="Customer Name"
								:colon="false">
									<a-input 
										name="customer"
										v-model="newSale.customer"
										placeholder="Customer Name"
										/>
							</a-form-item>
						</a-col>

						<a-col :span="24" :md="12">
							<a-form-item 
								class="my-0 mt-4 pb-0" 
								label="Payment Method"
								placeholder="Select Payment Method"
								:colon="false">
								<a-select placeholder="Select Payment Method" 
									name="method"
									v-model="newSale.method">
									<a-select-option v-for="option in methods"
										:value="option.uuid"
										:key="option.name">
										{{ option.name }}
									</a-select-option>
								</a-select>
							</a-form-item>
						</a-col>
					</a-row>

					<a-row class="pt-4">
				
						<a-col :span="24" class="text-right">
							<a-button type="primary" @click="onCreateItem" class="">
								<a-icon type="plus" theme="outlined" class="text-white mr-0" />Add New Item
							</a-button>
						</a-col>

					</a-row>

					<div class="mt-3">
						<a-row type="flex" :gutter="24" class="pb-4">
							<a-col :span="24" :md="24">
								<a-table class="mt-20 pb-2"  
									size="middle"
									:responsive="true"
									:bordered="false"
									:columns="columns" 
									:data-source="newSale.items" 
									:rowKey="record => record.productUuid"
									:pagination="false">

										<template slot="s_no" slot-scope="s_no, record, index">
											{{ index + 1 }}
										</template>
										

										<template slot="action" slot-scope="action, record, index">
											<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn" style="color: #FB8C00;" @click="onEditItem(index)">
												<a-icon type="form" />
											</a-button>
											&nbsp;&nbsp;
											<a-button id="txtBtn" type="text" class="pb-0 pt-0 txtBtn" style="color: #F44335;" @click="onDeleteItem(index)">
												<a-icon type="delete" theme="outlined"/>
											</a-button>
										</template>
									
								</a-table>
							</a-col>
						</a-row>
					</div>



					<a-row :gutter="24" class="pb-4">
						
						<a-col :span="24" :md="24">
							<a-form-item class="my-0 mt-4 pt-2 pb-2"> 
								<a-button type="primary" color="#D15A1B" :loading="newSale.loading" html-type="submit" class="">
									{{ newSale.isCreate ? 'Save' : 'Update' }}
								</a-button>
							</a-form-item>
						</a-col>
					</a-row>


				</a-form-model>
					
			</div>
		</a-card>


		<!-- FORM MODAL -->
		<a-modal v-model="newItem.showModal" :footer="null">
			<h4 class="text-center">{{ newItem.isCreate ? 'Add Item' : 'Edit Item'}}</h4>
			
			<a-form-model
				:model="newItem"
                :form="form"
                class="login-form mb-0 pb-0"
                layout="vertical"
				@submit.prevent="onSubmitItem">

					<a-form-item 
						class="my-0 mt-4 pb-0" 
						label="Product"
						placeholder="Select Product"
						:colon="false">
						<a-select placeholder="Select Product" 
							name="method"
							show-search
							v-model="newItem.productUuid"
							@change="onChangeProduct"
							:options="products.map(option => ({ label: `${option.name} (${option.price.price})`, value: option.uuid }))" />
					</a-form-item>

					<a-form-item 
						class="my-0 mt-4 pb-0" 
						label="Quantity"
						:colon="false">
							<a-input 
								name="quantity"
								type="number"
								v-model="newItem.quantity"
								@input="onChangeQuantity"
								placeholder=""
								/>
					</a-form-item>

					<a-form-item 
						class="my-0 mt-4 pb-0" 
						label="Discount"
						:colon="false">
							<a-input 
								name="discount"
								type="number"
								v-model="newItem.discount"
								@input="onChangeDiscount"
								placeholder=""
								/>
					</a-form-item>

					<a-form-item 
						class="my-0 mt-4 pb-0" 
						label="Selling Price(per 1 quantity)"
						:colon="false">
							<a-input 
								name="sellingPrice"
								type="number"
								disabled
								v-model="newItem.sellingPrice"
								placeholder=""
								/>
					</a-form-item>

					<a-form-item 
						class="my-0 mt-4 pb-0" 
						label="Amount"
						:colon="false">
							<a-input 
								name="amount"
								type="number"
								disabled
								v-model="newItem.amount"
								placeholder=""
								/>
					</a-form-item>

                    <a-form-item class="mt-4"> 
						<a-button type="primary" color="#D15A1B" :loading="newItem.loading" html-type="submit" class="py-1">
							{{ newItem.isCreate ? 'Add' : 'Update' }}
						</a-button>
					</a-form-item>

                </a-form-model>
			
		</a-modal>

    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";

    const columns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
			// width: "5%"
        },
		{
			title: 'Product',
			dataIndex: 'productName',
			//width: "15%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'PRICE(@)',
			dataIndex: 'sellingPrice',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'QUANTITY',
			dataIndex: 'quantity',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'DISCOUNT',
			dataIndex: 'discount',
			// width: "10%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: 'SUBTOTAL',
			dataIndex: 'amount',
			// width: "8%"
			// sorter: (a, b) => a.name.length - b.name.length,
			// sortDirections: ['descend', 'ascend'],
		},
		{
			title: '',
			dataIndex: 'action',
			scopedSlots: { customRender: 'action' },
			// width: "30%"
			// align: "center"
		},
	];


    const rules = {
      name: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ],
    };
  
    export default {
		components: {
			PageTitle,
		},
		data() {
			return {
            heading: 'Shop',
            subheading: 'Sales',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            fields: [ 'first_name', 'last_name', 'age' ],
            items: [
            { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            { age: 89, first_name: 'Geneva', last_name: 'Wilson' }
            ],
            striped: false,
            bordered: false,
            outlined: false,
            small: false,
            hover: false,
            dark: false,
            fixed: false,
            footClone: false,

            pagination: {
                search: '',
                loading: false,
                perPage: 10,
                currentPage: 1,
                perPageOptions: [
                    {label: "10", value: 10}, 
                    {label: "20", value: 20}, 
                    {label: "30", value: 30}, 
                    {label: "50", value: 50}
                ],
                total: 10
            },

            columns,
            message: '',

            products: [],
			methods: [
				{ uuid: "cash", name: "Cash" },
				{ uuid: "bank", name: "Bank" },
				{ uuid: "mobile-money", name: "Mobile Money" },
			],
            
            form: '',
            rules,
            newSale: {
                uuid: null,
                companyUuid: null,
                customer: "Guest",
                method: "cash",
				items: [],
                isCreate: true,
                loading: false,
                showModal: false,
            },


			newItem: {
				index: null,
                productUuid: null,
				productName: null,
                quantity: null,
				discount: 0,
				amount: 0,
				sellingPrice: 0,
                isCreate: true,
                loading: false,
                showModal: false,
            },

            deleteDataItem: {
                uuid: null,
                showModal: false,
            },

            userDetails: {
                user: null,
                company: null
            },

            notify: {
                text: null,
                type: null,
            },
        }
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'newItemForm' });
		},
		created() {
			this.form = this.$form.createForm(this, { name: 'newItemForm' })
            this.getUserDetails();
			this.getProducts();
		},
		methods: {

            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },

			async getProducts() {

                const currentPage = 1
				const perPage = 100000;

                let userDetails = await JSON.parse(localStorage.getItem("zegeUser"));

                let companyUuid = userDetails.company != null ? userDetails.company.uuid : null

                let url = `${this.$BACKEND_URL}/products/${companyUuid}?status=approved&page=${currentPage}&perPage=${perPage}`;

                this.$AXIOS.get(url).then((response) => {
                    if (response.status == 200) {
                        this.products = response.data.data;
                    }
                }).catch((error) => {
                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)
                });
            },

			onChangeProduct(productUuid) {

				let filter = this.products.filter(prod => prod.uuid == productUuid);

				if(filter.length > 0) {
					// eslint-disable-next-line no-console
					console.log(filter[0])
					this.newItem.productUuid = filter[0].uuid;
					this.newItem.productName = filter[0].name;
					this.newItem.sellingPrice = filter[0].price.price;

					if(this.newItem.quantity != null && parseInt(this.newItem.quantity) > 0) {
						this.newItem.amount = parseInt(filter[0].price) * parseInt(this.newItem.quantity);
					}
				}
			},

			onChangeQuantity(event) {
				// eslint-disable-next-line no-console
				// console.log(event)

				let quantity = event.target.value

				if(quantity != null && parseInt(quantity) > 0) {
					this.newItem.amount = parseInt(this.newItem.sellingPrice) * parseInt(quantity);
				}else{
					this.notify.text = "Quantity should atleast be 1 or more!";
					this.notify.type = "error"

					this.$notify(this.notify)
				}
			},

			onChangeDiscount(event) {

				let discount = event.target.value

				if(discount != null && parseInt(discount) > 0) {
					this.newItem.amount = parseInt(parseInt(this.newItem.sellingPrice) * parseInt(this.newItem.quantity)) - parseInt(discount);
				}else{
					this.newItem.amount = parseInt(this.newItem.sellingPrice) * parseInt(this.newItem.quantity);
				}
			},

			onCreateItem() {
				this.newItem.index = null;
                this.newItem.productUuid = null;
				this.newItem.productName = null;
                this.newItem.quantity = null;
				this.newItem.discount = 0;
				this.newItem.amount = 0;
				this.newItem.sellingPrice = 0;
				
				this.newItem.isCreate = true;
				this.newItem.loading = false;
				this.newItem.showModal = true;
            },

			onEditItem(index) {
				let item = this.newSale.items[index];

				if(item != null) {
					this.newItem.index = index;
					this.newItem.productUuid = item.productUuid;
					this.newItem.productName = item.productName;
					this.newItem.quantity = item.quantity;
					this.newItem.discount = item.discount;
					this.newItem.amount = item.amount;
					this.newItem.sellingPrice = item.sellingPrice;
					
					this.newItem.isCreate = false;
					this.newItem.loading = false;
					this.newItem.showModal = true;
				}
            },


			onDeleteItem(index) {

				this.newSale.items.splice(index, 1)
				
            },

			onSubmitItem() {
                
				if(this.newItem.productUuid != null && this.newItem.productUuid.length > 0 && this.newItem.quantity != null && parseInt(this.newItem.quantity) > 0 && this.newItem.amount != null && parseInt(this.newItem.amount) > 0) {

					if(this.newItem.isCreate) {
						this.newSale.items.push({
							productUuid: this.newItem.productUuid,
							productName: this.newItem.productName,
							quantity: this.newItem.quantity,
							discount: this.newItem.discount,
							amount: this.newItem.amount,
							sellingPrice: this.newItem.sellingPrice,
						})
					}else if(!this.newItem.isCreate){
						this.newSale.items[this.newItem.index].productUuid = this.newItem.productUuid;
						this.newSale.items[this.newItem.index].productName = this.newItem.productName;
						this.newSale.items[this.newItem.index].quantity = this.newItem.quantity;
						this.newSale.items[this.newItem.index].discount = this.newItem.discount;
						this.newSale.items[this.newItem.index].amount = this.newItem.amount;
						this.newSale.items[this.newItem.index].sellingPrice = this.newItem.sellingPrice;
					}

					this.newItem.isCreate = true;
					this.newItem.loading = false;
					this.newItem.showModal = false;
				}else {
					this.notify.text = "Please make sure you select s product and enter quantity before you submit!";
					this.notify.type = "error"

					this.$notify(this.notify)
				}
				
				
				
            },



			async handleSubmit() {

					this.newSale.loading = true;
		
					let url = `${this.$BACKEND_URL}/sales`

					this.newSale.companyUuid = this.userDetails.company.uuid;

					this.newSale.type = 'sale';

					this.$AXIOS.post(url, this.newSale).then(response => {

						if (response.status >= 200 && response.status < 210) {

							this.newSale.customer = "Guest";
							this.newSale.method = "cash";
							this.newSale.items = [];
							this.newSale.isCreate = true;
							this.newSale.showModal = false;

							this.notify.text = response.data.message
							this.notify.type = "success"

							this.$notify(this.notify)

						}

						this.newSale.loading = false;

					}).catch(error => {
					
						this.newSale.loading = false;
			
						if(error.response != null && error.response.status == 401) {
							this.$router.push(`/`);
						}

						this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
						this.notify.type = "error"

						this.$notify(this.notify)
			
					});

				
			},


		},
    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }
</style>